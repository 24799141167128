import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step beta ca provisioner`}</strong>{` -- create and manage the certificate authority provisioners`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step beta ca provisioner <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step beta ca provisioner`}</strong>{` command group provides facilities for managing the
certificate authority provisioners.`}</p>
    <p>{`WARNING: The 'beta' prefix is deprecated and will be removed in a future release.
Please use 'step ca provisioner ...' going forwards.`}</p>
    <p>{`A provisioner is an entity that controls provisioning credentials, which are
used to generate provisioning tokens.`}</p>
    <p>{`Provisioning credentials are simple JWK key pairs using public-key cryptography.
The public key is used to verify a provisioning token while the private key is
used to sign the provisioning token.`}</p>
    <p>{`Provisioning tokens are JWT tokens signed by the JWK private key. These JWT
tokens are used to get a valid TLS certificate from the certificate authority.
Each provisioner is able to manage a different set of rules that can be used to
configure the bounds of the certificate.`}</p>
    <p>{`In the certificate authority, a provisioner is configured with a JSON object
with the following properties:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`name`}</strong>{`: the provisioner name, it will become the JWT issuer and a good
practice is to use an email address for this.`}</li>
      <li parentName="ul"><strong parentName="li">{`type`}</strong>{`: the provisioner type, currently only "jwk" is supported.`}</li>
      <li parentName="ul"><strong parentName="li">{`key`}</strong>{`: the JWK public key used to verify the provisioning tokens.`}</li>
      <li parentName="ul"><strong parentName="li">{`encryptedKey`}</strong>{` (optional): the JWE compact serialization of the private key
used to sign the provisioning tokens.`}</li>
      <li parentName="ul"><strong parentName="li">{`claims`}</strong>{` (optional): an object with custom options for each provisioner.
Options supported are:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`minTLSCertDuration`}</strong>{`: minimum duration of a certificate, set to 5m by
default.`}</li>
          <li parentName="ul"><strong parentName="li">{`maxTLSCertDuration`}</strong>{`: maximum duration of a certificate, set to 24h by
default.`}</li>
          <li parentName="ul"><strong parentName="li">{`defaultTLSCertDuration`}</strong>{`: default duration of the certificate, set to 24h
by default.`}</li>
          <li parentName="ul"><strong parentName="li">{`disableRenewal`}</strong>{`: whether or not to disable certificate renewal, set to false
by default.`}</li>
          <li parentName="ul"><strong parentName="li">{`allowRenewalAfterExpiry`}</strong>{`: whether or not to allow certificate renewal of
expired certificates, set to false by default.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Examples`}</h2>
    <p>{`Add a single provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step beta ca provisioner add max@smallstep.com --type JWK --create
`}</code></pre>
    <p>{`Remove a provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step beta ca provisioner remove max@smallstep.com
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "add/"
              }}>{`add`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`add a provisioner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "remove/"
              }}>{`remove`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`remove a provisioner from the CA configuration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "get/"
              }}>{`get`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`get a provisioner from the CA configuration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "update/"
              }}>{`update`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`update a provisioner`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      